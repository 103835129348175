<template>
  <div class="wedding">


  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'Wedding',

});
</script>
