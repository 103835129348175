<template>
    <nav class="main-nav navbar navbar-expand-md navbar-dark bg-dark">
        <button class="navbar-toggler navbar-dark " type="button" data-toggle="collapse" data-target="#navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="toggle-title">Menu</span>
        <span class="navbar-toggler-icon"></span>
        </button> 

    <div id="navbar-collapse" class="navbar-collapse collapse text-center justify-content-center">
        <ul class="nav navbar-nav">
            <li class="nav-item"><router-link to="/" class="nav-link">Alison &amp; Brian</router-link></li>

            <li class="nav-item"><router-link to="our-story" class="nav-link">Our Story</router-link></li>

            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="ourDayDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Our Day
                </a>
                <ul class="dropdown-menu" aria-labelledby="ourDayDropdown">
                    <li><router-link to="pictures" class="dropdown-item">Pictures</router-link></li>
                    <li><router-link to="wedding" class="dropdown-item">Beach Wedding</router-link></li>
                    <li><router-link to="reception" class="dropdown-item">Reception</router-link></li>
                </ul>
                </li>

            <li class="nav-item"><router-link to="honeymoon" class="nav-link">Honeymoon</router-link></li>

            <li class="nav-item"><router-link to="firstfiveyears" class="nav-link">First 5 Years</router-link></li>

            <li class="nav-item"><router-link to="gallery" class="nav-link">Gallery</router-link></li>

        </ul><!--//nav-->
    </div><!--//navabr-collapse-->
    </nav>
</template>