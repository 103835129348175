import 'bootstrap'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/styles/main.scss'  // Import Main CSS file

createApp(App).use(store).use(router).mount('#app')
