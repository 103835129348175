import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Home from '../views/Home.vue'
import OurStory from '../views/OurStory.vue'

import Pictures from '../views/Pictures.vue'
import Wedding from '../views/Wedding.vue'
import Reception from '../views/Reception.vue'

import Honeymoon from '../views/Honeymoon.vue'

import Gallery from '../views/Gallery.vue'

import FirstFiveYears from '../views/FirstFiveYears.vue'



const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Alison & Brian',
    component: Home
  },
  {
    path: '/our-story',
    name: 'Our Story',
    component: OurStory
  },
  {
    path: '/pictures',
    name: 'Pictures',
    component: Pictures
  },
  {
    path: '/wedding',
    name: 'Wedding',
    component: Wedding
  },
  {
    path: '/reception',
    name: 'Reception',
    component: Reception
  },
  {
    path: '/honeymoon',
    name: 'Honeymoon',
    component: Honeymoon
  },
  {
    path: '/firstfiveyears',
    name: 'First 5 Years',
    component: FirstFiveYears
  },

  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
