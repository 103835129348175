<template>
  <div class="home">

    <div id="carouselHomeIndicators" class="carousel slide" data-bs-ride="carousel">
      <ol class="carousel-indicators">
        <li data-bs-target="#carouselHomeIndicators" data-bs-slide-to="0" class="active"></li>
        <li data-bs-target="#carouselHomeIndicators" data-bs-slide-to="1"></li>
        <li data-bs-target="#carouselHomeIndicators" data-bs-slide-to="2"></li>
        <li data-bs-target="#carouselHomeIndicators" data-bs-slide-to="3"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../assets/r016.jpg" class="d-block w-100" alt="Rings">
        </div>
        <div class="carousel-item">
          <img src="../assets/r001.jpg" class="d-block w-100" alt="Wedding Dress">
        </div>
        <div class="carousel-item">
          <img src="../assets/r414.jpg" class="d-block w-100" alt="Starfish">
        </div>
        <div class="carousel-item">
          <img src="../assets/r661.jpg" class="d-block w-100" alt="Silhouette">
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselHomeIndicators" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselHomeIndicators" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'Home',

});
</script>
