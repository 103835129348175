
import { defineComponent } from 'vue';

import MainNav from '@/components/MainNav.vue';


export default defineComponent({
  name: 'App',
  components: {
    MainNav
  }
});

