<template>
  <div class="our-story">


  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'OurStory',

});
</script>
